<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="2" sm="2" lg="2" xl="2">
        <v-toolbar dense elevation="0">
          <div class="mr-5">
            <h3>Referrals</h3>
          </div>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template #activator="{ on: menu }">
              <v-tooltip bottom color="primary">
                <template #activator="{ on: tooltip }">
                  <v-btn class="gradient-bg2" small dark v-on="{ ...menu, ...tooltip }">
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Show Credits for Referrals</span>
              </v-tooltip>
            </template>
            <v-toolbar dense height="30px" class="gradient-bg white--text">
              <v-spacer />
              <v-btn dark icon @click="menu = false">
                <v-icon x-small>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card class="elevation-0 overflow-hidden">
              <v-card-text align="center" class="mt-n3">
                <span  style="font-size: 16px" class="font-weight-medium">Current Credits
                </span>
                <v-row>
                  <v-col cols="12"  md="8" sm="8" lg="8" xl="8" align="left">
                    <span class="font-weight-medium"> For First Referral 
                    </span><br>
                    <span class="font-weight-medium"> For Second Referral 
                    </span>
                  </v-col>
                  <v-col cols="12" md="4" sm="4" lg="4" xl="4" align="right">
                    <span style="font-size: 13px"><strong> : {{ firstReferrals }} </strong>
                    </span><br>
                    <span style="font-size: 13px"><strong> : {{ secondReferrals }} </strong>
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="2" sm="2" lg="2" xl="2">
        <v-select v-model="ReferralsStatus" :items="ReferralsStatusItems" dense label="Filter By Status" outlined
          item-text="text" item-value="value">
        </v-select>
      </v-col>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense class="elevation-0">
          <v-text-field v-model="search" label="search" class="mr-2 mt-5" dense  append-icon="mdi-magnify"></v-text-field>
          <v-tooltip bottom right color="primary">
            <template v-slot:activator="{ on }">
              <v-btn @click="UpdateDialog = true" small v-on="on" class="mr-2 gradient-bg white--text">
                <v-icon dense>mdi-update</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Update Referral Credits</span>
          </v-tooltip>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table :headers="ReferralsData" :items="ReferralsItems" dense class="overflow-y-auto overflow"
          :fixed-header="fixed" :height="height" :loading="isLoading" :search="search">
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No Referrals Data available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.referred_date`]="{ item }">
            <span>{{ get_date(item.referred_date) }}</span>
          </template>
          <template v-slot:[`item.referral_status`]="{ item }">
            <div :class="
                item.referral_status == 'PENDING'
                  ? 'orange--text'
                  : 'green--text'
              " v-text="item.referral_status"></div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <UpdateReferrals :current_first_credit_value="firstReferrals" :current_second_credit_value="secondReferrals"
      @close="get_status" :UpdateDialog="UpdateDialog" />
  </div>
</template>

<script>
  import Overlay from "@/components/Overlay.vue";
  import SnackBar from "@/components/SnackBar.vue";
  import {
    API,
    graphqlOperation
  } from "aws-amplify";
  import {
    GetReferralPending,
    getReferal
  } from "@/graphql/queries.js";
  import UpdateReferrals from "@/components/Dialogs/Referrals/UpdateReferrals.vue";

  export default {
    components: {
      Overlay,
      SnackBar,
      UpdateReferrals,
    },
    data: () => ({
      UpdateDialog: false,
      ReferralsData: [{
          text: "Referred Org",
          value: "referred_by_organisation_name"
        },
        {
          text: "Referred By",
          value: "referred_by"
        },
        {
          text: "Referred On",
          value: "referred_date"
        },
        {
          text: "Referred To Org",
          value: "referred_to_organisation_name"
        },
        {
          text: "Referred To",
          value: "referred_to"
        },
        {
          text: "Scans Remaining",
          value: "total_scans"
        },
        {
          text: "Status",
          value: "referral_status"
        },
      ],
      ReferralsItems: [],
      menu: false,
      search: "",
      fixed: true,
      height: 0,
      overlay: false,
      SnackBarComponent: {},
      isLoading: false,
      ReferralsStatus: "ALL",
      ReferralsStatusItems: [{
          text: "ALL",
          value: "ALL"
        },
        {
          text: "PENDING",
          value: "PENDING"
        },
        {
          text: "SUCCESS",
          value: "SUCCESS"
        },
      ],
      firstReferrals: 0,
      secondReferrals: 0,
    }),
    mounted() {
      this.height = window.innerHeight - 190;
      this.get_referrals_list();
      this.get_referrals();
    },
    methods: {
      get_status() {
        this.get_referrals_list();
        this.UpdateDialog = false;
        this.get_referrals();
      },
      get_date(date) {
        let a = new Date(date * 1000).toLocaleTimeString("en-In", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return a.split(",")[0].toUpperCase();
      },
      async get_referrals_list() {
        this.ReferralsItems = [];
        this.isLoading = true;
        this.overlay = true;
        try {
          let result = await API.graphql(
            graphqlOperation(GetReferralPending, {
              input: {},
            })
          );
          this.ReferralsItems = JSON.parse(result.data.GetReferralPending).Items;
          this.isLoading = false;
          this.overlay = false;
        } catch (err) {
          console.log("error", err);
          this.isLoading = false;
          this.overlay = false;
        }
      },
      async get_referrals() {
        //this.Referrals = "";
        this.firstReferrals = 0;
        this.secondReferrals = 0;

        this.isLoading = true;
        this.overlay = true;
        try {
          let result = await API.graphql(
            graphqlOperation(getReferal, {
              input: {},
            })
          );
          this.firstReferrals = JSON.parse(result.data.getReferal).Items[0].firstcredits;
          this.secondReferrals = JSON.parse(result.data.getReferal).Items[0].secondCredits;
          this.isLoading = false;
          this.overlay = false;
        } catch (err) {
          console.log("error", err);
          this.isLoading = false;
          this.overlay = false;
        }
      },
    },
  };
</script>

<style>
</style>