<template>
  <div>
    <v-dialog persistent width="500px" v-model="UpdateDialog">
      <v-card class="overflow-hidden">
        <v-toolbar dense elevation="0" class="gradient-bg white--text">
          <v-toolbar-title>Update referral Credit</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close_dialog()" icon dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="ma-5">
          <v-row no-gutters>
            <v-col cols="12" md="5" class="mt-2">
              <span style="font-size: 13px"
                ><strong> For Consuming 500 credits</strong></span
              >
            </v-col>
            <v-col cols="12" md="7">
              <v-select
                v-model="firstReferral"
                :items="referralItems"
                label="Update Referral Credit"
                dense
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="5" class="mt-2">
              <span style="font-size: 13px"
                ><strong> For Consuming 1000 credits</strong></span
              >
            </v-col>
            <v-col cols="12" md="7">
              <v-select
                v-model="secondReferral"
                :items="referralItems"
                label="Update Referral Credit"
                dense
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
        </div>

        <v-card-text class="pa-0 ma-0" align="center">
          <v-btn
            :loading="isLoading"
            class="gradient-bg white--text mt-n5"
            @click="call_update()"
            >Update
          </v-btn>
        </v-card-text>
        <v-card-actions />
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";

import { API, graphqlOperation } from "aws-amplify";
import { UpdateReferralCreditsToOrganisation } from "@/graphql/mutations.js";
export default {
  components: {
    SnackBar,
  },
  props: {
    UpdateDialog: Boolean,
    current_first_credit_value: Number,
    current_second_credit_value: Number,
  },
  data: () => ({
    SnackBarComponent: {},
    isLoading: false,
    firstReferral: "",
    secondReferral: "",
    referralItems: [
      {
        text: "100 Credits",
        value: 100,
      },
      {
        text: "500 Credits",
        value: 500,
      },
      {
        text: "1000 Credits",
        value: 1000,
      },
    ],
  }),
  watch: {
    UpdateDialog(val) {
      if (val == true) {
        this.firstReferral = this.current_first_credit_value;
        this.secondReferral = this.current_second_credit_value;
      }
    },
  },
  methods: {
    async call_update() {
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(UpdateReferralCreditsToOrganisation, {
            input: {
              firstcredits: this.firstReferral,
              secondCredits: this.secondReferral,
            },
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(
              res.data.UpdateReferralCreditsToOrganisation
            ).Message,
          };
          this.$emit("close");
        });

        this.isLoading = false;
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "System Error",
        };
      }
    },
    close_dialog() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
